<template>
  <div class="jd_list_box">
    <div v-for="(item,index) in searchListData" :key="index" class="jd_list" @click="jumpToDetails(item)">

      <div v-if="item.markerUrl" class="markerUrlIcon">
        <img :src="item.markerUrl">
      </div>

      <div class="jd_list_ovs">
        <imgLoading class="jd_list_img" :src="item.productImage" />
      </div>
      <div class="jd_list_son">
        <div class="jd_name">
          <img :src="item.icon" alt="">
          <span>{{ item.productName }}</span>
        </div>
        <h6 v-if="item.shopTitle">{{ item.shopTitle }}</h6>
        <div class="jd_logo_top">
          <span v-if="item.couponFee" class="jd_logo_bj">{{ item.couponFee }}元券</span>
          <span v-if="item.growthValueNew.share" class="jd_logo_share">分享赚{{ item.growthValueNew.share }}</span>
          <span v-if="item.rebate_price" class="jd_logo_share">分享赚{{ item.rebate_price }}</span>
          <span v-if="item.shareSavePrice" class="jd_logo_share">分享赚{{ item.shareSavePrice }}</span>
        </div>
        <div class="jd_logo_bot">
          <div class="jd_logo_ov">
            <span class="jd_price">¥{{ (item.vipPrice) }}</span>
            <span class="jd_original_price">¥{{ item.price }}</span>
          </div>
          <div class="jd_earn_price">已售{{ item.orderPayCount }}件</div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable eqeqeq */
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import { switchTaoBaoAuth } from '@/services/api'
import imgLoading from '@/component/imgLoading'

export default {
  name: 'SearchListRow',
  components: {
    imgLoading
  },
  props: {
    'searchListData': {
      type: null,
      default: null
    },
    'searchParams': {
      type: null,
      default: null
    },
    'loadStatus': {
      type: null,
      default: null
    }
  },
  data() {
    return {
      id: this.$store.state.isTabVant
    }
  },
  mounted() {
  },
  methods: {
    // jump_buy(url) {
    //     location.href = url;
    // },
    onLoad() {
      this.$nextTick(() => {
        this.$emit('sendPage', { nowPage: this.searchParams.page += 1 })
      })
    },

    // 跳转产品详情页
    jumpToDetails(obj) {
      // 京东商城", channelid: 3
      // "京东", channelid: 100
      // "京东优选", channelid: 3
      // "拼多多", channelid: 98
      // "淘宝", channelid: 99
      // "唯品会", channelid: 101
      // "悦淘优选", channelid: 5
      // "海购考拉", channelid: 6
      // /goodsDetailCPS?goodsId='商品ID'&status=（3:京东；4:拼多多；5:淘宝；6:唯品会）
      if (obj.channelId == 3 || obj.channelId == 100) { // 京东
        this.$router.push({
          path: '/goodsDetailCPS',
          query: {
            goodsId: obj.productId,
            status: 3
          }
        })
        // this.$router.push({
        //     path: '/goodsDetailZY',
        //     query: {
        //         goodsId: obj.productId,
        //         skuid: obj.skuId
        //     }
        // })
      } else if (obj.channelId == 98) { // 拼多多
        this.$router.push({
          path: '/goodsDetailCPS',
          query: {
            goodsId: obj.productId,
            status: 4
          }
        })
      } else if (obj.channelId == 99) { // 淘宝
        const midParams = {
          mid: window.localStorage.getItem('uid')
        }
        if (!midParams.mid || midParams.mid == 'undefined') {
          Toast('该商品需要登录后查看')
          return
        } else {
          // 点击淘宝时判断用户授权
          switchTaoBaoAuth(midParams).then(ele => {
            if (ele.data.channel_bind && ele.data.auth) {
              this.$router.push({
                path: '/goodsDetailCPS',
                query: {
                  goodsId: obj.productId,
                  status: 5
                }
              })
            } else {
              Toast('该账户未进行淘宝授权')
            }
          })
        }

        // let loginStatus = localStorage.getItem('uid');
        // if(loginStatus){
        //   this.getTbsq(obj.productId);
        // }else{
        //   this.$router.push({
        //     path:'/login',
        //   })
        // }
      } else if (obj.channelId == 101) { // 唯品会
        this.$router.push({
          path: '/goodsDetailCPS',
          query: {
            goodsId: obj.productId,
            status: 6
          }
        })
      } else if (obj.channelId == 5) { // 自营商品
        // goodsDetailZY?liveId='直播ID'&liveUserId='直播人ID'&parentTypeId='产品类型'&goodsId='产品ID'&skuid='产品规格ID'
        // goodsDetailJD?goodsId='商品ID'京东  、/goodsDetailPDD?goodsId='商品ID' 拼多多

        //* ****自营、海购考拉 先用自营 地址***//
        //* ****自营、海购考拉 先用自营 地址***//
        //* ****自营、海购考拉 先用自营 地址***//
        this.$router.push({
          path: '/goodsDetailZY',
          query: {
            goodsId: obj.productId,
            skuid: obj.skuId
          }
        })
      } else if (obj.channelId == 6) { // 海购考拉
        this.$router.push({
          path: '/goodsDetailZY',
          query: {
            goodsId: obj.productId,
            skuid: obj.skuId
          }
        })
      } else if (obj.channelId == 12) { // 大牌清
        this.$router.push({
          path: '/goodsDetailZY',
          query: {
            goodsId: obj.productId,
            skuid: obj.skuId
          }
        })
      }
    }

    // 获取淘宝授权
    // getTbsq(productId){
    //   let that = this;
    //   let params ={};
    //   let mid = localStorage.getItem("uid");
    //   let roomid=this.$route.query.room_id;
    //   let goodsId = productId;
    //
    //   let uidroomid = sessionStorage.getItem("uidroomid");
    //
    //   if(roomid){
    //     let uidroomid2=mid+","+roomid+","+goodsId
    //     sessionStorage.setItem("uidroomid",uidroomid2);
    //     params={
    //       "mid":mid,
    //       "roomId":roomid,
    //       "goodsId":goodsId
    //     }
    //   }else if(uidroomid){
    //     var stringArr= uidroomid.split(",");
    //     if(goodsId==stringArr[2]){
    //       params={
    //         "mid":stringArr[0],
    //         "roomId":stringArr[1],
    //         "goodsId":stringArr[2]
    //       }
    //     }else{
    //       params={
    //         "mid":stringArr[0],
    //         "roomId":0,
    //         "goodsId":goodsId
    //       }
    //     }
    //   }else{
    //     var uidroomid2=mid+",0,"+goodsId;
    //     sessionStorage.setItem("uidroomid",uidroomid2);
    //     params={
    //       "mid":mid,
    //       "roomId":0,
    //       "goodsId":goodsId
    //     }
    //   }
    //   switchTaoBaoAuth_new(params).then(data=>{
    //     if(data.code=="200"){
    //       //that.skiInfo(params);//调取商品详情
    //       this.$router.push({
    //           path: '/goodsDetailCPS',
    //           query: {
    //               goodsId: productId,
    //               status: 5
    //           }
    //       })
    //     }
    //     if(data.code=="201"){
    //       var ua = window.navigator.userAgent.toLowerCase();
    //       if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //         Toast("请复制链接，在浏览器中打开");
    //         //that.go = true
    //       }else{
    //         //toast("访问该页面，需获得淘宝授权");
    //         window.location.href=data.data.url;
    //
    //       }
    //     }
    //   })
    //
    //   // $.ajax({
    //   //   url:"https://single.yuetao.group/outside/tbkAuthCheck",
    //   //   type: "POST",
    //   //   data:params,
    //   //   dataType: "json",
    //   //   success: function (data) {
    //   //     if(data.code=="200"){
    //   //       that.skiInfo(params);//调取商品详情
    //   //     }
    //   //     if(data.code=="201"){
    //   //       var ua = window.navigator.userAgent.toLowerCase();
    //   //       if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //   //         //toast("请复制链接，在浏览器中打开");
    //   //         that.go = true
    //   //       }else{
    //   //         //toast("访问该页面，需获得淘宝授权");
    //   //         window.location.href=data.data.url;
    //   //
    //   //       }
    //   //     }
    //   //   }
    //   // })
    // },

  }
}
</script>
<style lang="less" scoped>

.jd_list_box {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  box-sizing: border-box;
  background: #f6f6f6;
  overflow: hidden;

  .jd_list {
    width: 169px;
    height: 280px;
    background: #fff;
    margin-right: 12px;
    float: left;
    margin-bottom: 6px;
    margin-top: 6px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }

  .jd_list:nth-child(2n) {
    margin-right: 0px;
  }

  .jd_list_ovs {
    position: relative;

    .jd_list_img {
      width: 100%;
    }

    .sale_num {
      width: 100%;
      height: 16%;
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(97, 96, 96, 0.7));
    }

    .sale_num_style {
      position: absolute;
      right: 12px;
      bottom: 6px;
      color: #fff;
      font-size: 14px;
    }
  }

  .jd_list_son {
    padding: 0 7px;
    box-sizing: border-box;
    text-align: left;
    margin-top: 6px;

    h6 {
      font-size: 10px;
      transform: scale(.8, .8);
      margin: 2px 2px 2px -16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .jd_name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 18px;

      img {
        width: 14px;
        vertical-align: -1px;
        margin-right: 4px;
      }

      .jd_logo {
        background: #ff001c;
        color: #fff;
        border-radius: 3px;
        padding: 0px 1px;
        font-weight: bold;
        box-sizing: border-box;
        font-size: 8px;
        margin-right: 3px;
      }
    }

    .jd_logo_top {
      margin-top: 6px;
    }

    .jd_logo_ov {
      float: left;

      .jd_price {
        color: #ff001c;
        font-size: 14px;
      }

      .jd_original_price {
        text-decoration: line-through;
        color: #bbb;
        font-size: 12px;
        margin-left: 2px;
      }
    }

    .jd_logo_bot {
      overflow: hidden;
      position: absolute;
      bottom: 10px;
      //width: 150px;
    }

    .jd_logo_bj {
      margin-top: 6px;
      background: url("../../assets/images/yhj_red.png") no-repeat;
      background-size: 100% 100%;
      width: 60px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      font-size: 10px;
      color: #fff;
      margin-right: 5px;
      padding: 1px 2px;
      box-sizing: border-box;
    }

    .jd_logo_share {
      background: rgba(236, 109, 109, .34);
      font-size: 10px;
      color: red;
      padding: 1px 2px;
      box-sizing: border-box;
    }

    .jd_earn_price {
      font-size: 10px;
      color: #999;
      float: right;
      margin-top: 4px;
      transform: scale(.9, .9);
    }

  }
}

.markerUrlIcon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 98;

  img {
    width: auto;
    height: 70px;
  }

}
</style>
