<template>
  <div class="main">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <slot slot="scrollList">
        <van-sticky>
          <!-- 搜索框 -->
          <div
            v-if="fromPageType === 1 || fromPageType === 3 || fromPageType === 4"
            class="searchmain"
          >
            <div class="searchdiv">
              <form action="/">
                <van-search
                  v-model="searchParams.keyword"
                  :left-icon="icon"
                  shape="round"
                  placeholder="请输入商品名称"
                  @search="goodsSearch"
                />
              </form>
            </div>
          </div>
          <!-- 首页过来的 品牌分类 -->
          <div v-if="fromPageType === 1 && searchBanner.length > 0" class="vantabdiv">
            <van-tabs ref="tabs" v-model="active" @click="changeSearchMenu">
              <van-tab
                v-for="(item) in searchBanner"
                :key="item.channelid"
                :name="item.channelid"
                :title="item.title"
              />
            </van-tabs>
          </div>
          <!-- img -->
          <div v-if="bannerImg && bannerImg[0]" class="banner_img" @click="btnBanner(bannerImg[0])">
            <img :src="bannerImg[0].url" alt="">
          </div>
          <!--综合、销量、价格。。。排序分类-->
          <div v-if="fromPageType === 1 || fromPageType === 2" class="pxdiv">
            <!--tab 筛选 组件-->
            <filterTabs :options="options" />
          </div>
        </van-sticky>
        <!--搜索 商品列表-->
        <div class="goodsList">
          <!-- 首页搜索商品-->
          <!-- {{searchParams}}{{fromPageType}} -->
          <SearchListRow
            v-if="fromPageType === 1"
            :search-list-data="searchData"
            :search-params="searchParams"
            :load-status="loadStatus"
            @sendPage="changePage"
          />
          <!--生鲜搜索 商品-->
          <FreshListRow
            v-if="fromPageType === 2"
            :search-list-data="searchData"
            :search-params="freshParams"
            :load-status="loadStatus"
            @sendPage="changePage"
          />
          <!--京东、拼多多搜索列表-->
          <ProductList
            v-if="fromPageType === 3 || fromPageType === 4"
            :search-list-data="searchData"
            :search-params="jdPddParams"
            :load-status="loadStatus"
            :page-type="fromPageType"
            @sendPage="changePage"
          />

          <h2 v-if="searchData == 0">暂无此商品</h2>
        </div>
        <!--筛选-->
        <rightSidebar :data-sxlist="dataSxlist" />
      </slot>
    </mescroll>
  </div>
</template>
<script>
/* eslint-disable eqeqeq */
import SearchListRow from '@/component/Goods/searchListRow'
import FreshListRow from '@/component/Goods/freshListRow'
import ProductList from '@/component/Goods/productList'
import filterTabs from '@/component/filterTabs/index'
import rightSidebar from '@/component/filterTabs/rightSidebar'
import { Search, Sticky, Tab, Tabs } from 'vant'
import vue from 'vue'

vue.use(Sticky)
  .use(Tab)
  .use(Tabs)
  .use(Search)
import {
  newEndSearchData,
  freshSearch,
  jdSearch,
  pddSearch,
  getscreen
} from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'
import icon from '../../assets/images/search.png'
import { fetchJDKeywordsList } from '@/services/shop-cps/shop-jd-cps'
import { fetchOptimizationSearchMallList } from '@/services/optimization'

export default {
  name: 'GoodsList',
  components: {
    SearchListRow,
    FreshListRow,
    ProductList,
    mescroll,
    filterTabs,
    rightSidebar
  },
  data() {
    return {
      icon,
      dataSxlist: {
        dataList: '', //  侧边栏 接口 数据
        isShow: false, // 侧边栏 显示隐藏
        btnDetermine: this.btnDetermine // 点击确定回调
      },
      isIndex: 0,
      show: false,
      options: {
        active: 0,
        activeChange: (index) => {
          this.searchParams.page = 1
          this.sxparmas.sorttype = index + 1
          this.searchParams.sorttype = index + 1
          // tab 切换
          // 其他接口 公共的 list
          this.newSearch()
        },
        tabsList: [
          {
            title: '综合',
            sort: {
              showSort: false,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange(type)
              }
            }
          },
          {
            title: '销量',
            sort: {
              showSort: true,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange(type)
              }
            }
          },
          {
            title: '价格',
            sort: {
              showSort: true,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange(type)
              }
            }
          },
          {
            title: '筛选',
            filter: {
              showFilter: true,
              filterChange: () => {
                // this.show = true;
                this.dataSxlist.isShow = true
              }
            }
          }
        ]
      },
      // 又来承接的数组
      orderlist: [],
      didx: 1,
      idx: '',
      sxlist: '',
      value1: '',
      value2: '',
      type: true,
      bannerImg: '',

      active: this.$store.state.isTabVant,
      // mescroll 上拉加载和下拉刷新
      page: 1,
      mescrollDown: {
        use: true,
        // top: "50px",
        bottom: '0px'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      // mescroll 上拉加载和下拉刷新

      showSx: false,
      searchData: [], // 搜索数据
      searchBanner: [], // 首页搜索过来tab 品牌名称
      searchParams: {
        // 首页搜索参数
        sorttype: 1, //  1 全部  2 销量  3 价格
        sort: 0, // 1价格正序  0 倒序
        channelId: this.$store.state.isbtnTabVal,
        mid: '',
        keyword: '',
        isCoupon: 0,
        page: 1,
        pageSize: 10,

        categoryFirstId: '',
        categoryThreeId: '',
        minprice: '',
        maxprice: '',
        brandId: ''
      },

      sxparmas: {
        indexKey: '',
        channelId: 'all',
        categoryFirstId: '',
        categorySecondId: '',
        categoryThreeId: '',
        keyword: '',
        sorttype: 1, //  1 全部  2 销量  3 价格
        sort: 0, // 1价格正序  0 倒序
        page: 1,
        pageSize: 10,
        minprice: '',
        maxprice: '',
        brandId: ''
      },

      freshParams: {
        // 生鲜\日用家居\美妆个护搜索参数
        sorttype: 1, // 1 综合 2销量 3价格
        sort: 0,
        channelId: 'all',
        keyword: '',
        page: 1,
        pageSize: 10
      },
      jdPddParams: {
        // 京东\拼多多搜索参数
        keyword: '',
        page: 1,
        pageSize: 10
      },
      loadStatus: {
        // 加载状态/翻页
        loading: false,
        finished: false,
        error: false
      },
      fromPageType: 0, // 来自传参的页面
      sortType: 1, // 默认搜索类型
      // sortNumber: 0,//默认搜索方式

      channelId: null
    }
  },
  created() {
    const { channelId } = this.$route.query
    this.channelId = channelId ? Number(channelId) : ''
    getscreen(this.searchParams).then((res) => {
      // this.sxlist = res.data;
      this.dataSxlist.dataList = res.data
    })
    this.searchParams.keyword = this.$route.query.searchKeyword
    if (this.channelId) {
      this.searchParams.channelId = Number(channelId)
    }
    this.freshParams.keyword = this.$route.query.searchKeyword
    this.jdPddParams.keyword = this.$route.query.searchKeyword

    // 判断传参页面
    switch (this.$route.query.fromPage) {
      case 'goodsSearch':
        this.fromPageType = 1 // 首页搜索传过来
        this.newSearch()
        break
      case 'fresh':
        this.fromPageType = 2 // 生鲜搜索
        this.newFreshSearch()
        break
      case 'house':
        this.fromPageType = 2 // 日用家居
        this.newFreshSearch()
        break
      case 'beauty':
        this.fromPageType = 2 // 美妆个护
        this.newFreshSearch()
        break
      case 'jdList':
        this.fromPageType = 3 // 京东搜索传过来
        this.jdPddProductSearch()
        break
      case 'pddList':
        this.fromPageType = 4 // 拼多多搜索传过来
        this.jdPddProductSearch()
        break
    }
  },
  beforeMount() {
    const newTitle = this.$route.query.searchKeyword
    document.title = newTitle || '商品列表'
  },
  mounted() {},
  methods: {
    // tab 切换
    typeChange(type) {
      if (type == 'add') {
        this.searchParams.sort = 1
      } else {
        this.searchParams.sort = 0
      }
      this.searchParams = {
        ...this.searchParams
      }
      this.newSearch()
    },
    // 点击 img 跳转
    btnBanner(item) {
      if (item.redirectType === 3) {
        const obj = {
          product_type: 1,
          goodId: item.id,
          skuId: item.skuId
        }
        this.$store.dispatch('godetail', obj)
      } else if (item.redirectType === 29) {
        window.location.href = item.redirectUrl
      } else if (item.redirectType === 39) {
        this.$router.push({ path: '/acthds', query: { id: item.id }})
      }
    },

    // 回调 接口
    btnDetermine: function(data) {
      this.searchParams = {
        ...data,
        ...this.searchParams
      }
      // newEndSearchData(data).then((res) => {
      //   this.searchData = res.data.doc
      // })
      this.newSearch()
    },
    // 搜索下啦
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.searchData = []
          // 把请求到的数据添加到列表
          this.searchData = this.searchData.concat(arr)

          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet(
      pdType,
      pageNum,
      pageSize,
      successCallback,
      errorCallback
    ) {
      this.searchParams.page = pageNum
      this.searchParams.pageSize = pageSize
      if (this.fromPageType === 1) {
        this.newSearch(successCallback)
      } else if (this.fromPageType === 2) {
        freshSearch(this.freshParams).then((res) => {
          if (res.code == 200) {
            successCallback(res.data.doc)
          }
        })
      } else if (this.fromPageType === 3 || this.fromPageType === 4) {
        jdSearch(this.jdPddParams).then((res) => {
          if (res.code == 200) {
            successCallback(res.data.doc)
          }
        })
        pddSearch(this.jdPddParams).then((res) => {
          if (res.code == 200) {
            successCallback(res.data.doc)
          }
        })
      }
    },
    // 上下拉结束
    sx() {
      this.showSx = true
    },
    closesx() {
      this.showSx = false
    },
    enterslect() {},
    changePage(e) {
      switch (this.fromPageType) {
        case 1:
          this.searchParams.page = e.nowPage // 首页搜索传过来
          this.newSearch()
          break
        case 2:
          this.freshParams.page = e.nowPage // 生鲜搜索\日用家居\美妆个护 搜索传过来
          this.newFreshSearch()
          break
        case 3:
          this.jdPddParams.page = e.nowPage // 京东主页搜索传过来
          this.jdPddProductSearch()
          break
        case 4:
          this.jdPddParams.page = e.nowPage // 拼多多主页搜索传过来
          this.jdPddProductSearch()
          break
      }
    },
    // 首页过来的搜索
    newSearch(call) {
      const uid = window.localStorage.getItem('uid')
      const { channelId, sort, keyword, isCoupon, page, sorttype, minprice, maxprice } = this.searchParams
      let params = this.searchParams
      let c = newEndSearchData

      console.log(channelId)
      if (channelId === 3 || channelId === 100) {
        let sortName = ''
        // 1 全部  2 销量  3 价格
        if (sorttype === 1) {
          sortName = ''
        }
        if (sorttype === 2) {
          sortName = 'inOrderCount30DaysSku'
        }
        if (sorttype === 3) {
          sortName = 'price'
        }
        params = {
          keyword, // – 关键词
          isCoupon, // 是否有优惠券
          channelId,
          mid: uid,
          page,
          sortName: sortName, // – 排序名称
          pricefrom: minprice, // – 低价格
          priceto: maxprice, // 高价格
          sort: sort === 0 ? 'asc' : 'desc'
        }
        c = fetchJDKeywordsList
      } else if (channelId === 5) {
        // 悦淘优选商品筛选
        c = fetchOptimizationSearchMallList
      }

      c(params)
        .then((res) => {
          if (res.code == 200) {
            this.searchBanner = res.data.menu && res.data.menu.filter(i => i.is_outside === 1)
            this.active = this.searchBanner.findIndex(i => i.channelid === channelId) || 0
            console.log(this.searchBanner)
            console.log(this.active)
            this.bannerImg = res.data.banner
            this.loadStatus.loading = false
            call && call(res.data.doc)
            if (res.data.doc.length < 1 || !res.data.doc) {
              this.loadStatus.finished = true
              return
            } else {
              this.searchData = page === 1 ? res.data.doc : this.searchData.concat(res.data.doc)
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.loadStatus.error = true
        })
        .finally(() => {
          this.loadStatus.loading = false
        })
    },
    // tab切换【仅当首页过来的搜索】
    changeSearchMenu(index) {
      this.searchParams.channelId = index
      this.searchParams.page = 1
      this.searchData = []
      this.newSearch()
    },
    // 搜索框输入关键字搜索【当首页过来 或 京东、拼多多过来】
    goodsSearch(val) {
      // 存储搜索历史_丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅___________
      const newKeyList = JSON.parse(
        window.localStorage.getItem('searchHistoryKey')
      )
      this.searchHistoryList = newKeyList.length >= 1 ? newKeyList : []
      if (this.searchHistoryList.indexOf(val) < 0) {
        this.searchHistoryList.unshift(val)
        const localObj = JSON.stringify(this.searchHistoryList)
        window.localStorage.setItem('searchHistoryKey', localObj)
      }
      // 存储搜索历史_丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄___________
      if (val.trim() && this.fromPageType === 1) {
        this.searchParams.keyword = val
        this.searchParams.page = 1
        this.searchData = []
        this.newSearch()
      } else if (
        val.trim() &&
        (this.fromPageType === 3 || this.fromPageType === 4)
      ) {
        this.jdPddParams.keyword = val
        this.jdPddParams.page = 1
        this.searchData = []
        this.jdPddProductSearch()
      }
    },
    // 生鲜列表搜索
    newFreshSearch() {
      freshSearch(this.freshParams)
        .then((res) => {
          if (res.code == 200) {
            this.loadStatus.loading = false
            if (res.data.doc.length < 1 || !res.data.doc) {
              this.loadStatus.finished = true
              return
            } else {
              this.searchData = this.searchData.concat(res.data.doc)
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.loadStatus.error = true
        })
        .finally(() => {
          this.loadStatus.loading = false
        })
    },
    // 京东拼多多列表搜索
    jdPddProductSearch() {
      (this.fromPageType == 3
        ? jdSearch(this.jdPddParams)
        : pddSearch(this.jdPddParams)
      )
        .then((res) => {
          if (res.code == 200) {
            this.loadStatus.loading = false
            if (res.data.length < 1 || !res.data) {
              this.loadStatus.finished = true
              return
            } else {
              this.searchData = this.searchData.concat(res.data)
              // 【优惠卷】数据问题 做的判断
              this.searchData.forEach((ele) => {
                if (this.fromPageType == 3) {
                  if (ele.coupon.discount) {
                    ele.discount = ele.coupon.discount
                  } else {
                    ele.discount = 0
                  }
                } else if (this.fromPageType == 4) {
                  if (ele.coupon.length >= 1) {
                    if (ele.coupon[0].coupon_discount) {
                      ele.discount = ele.coupon[0].coupon_discount
                    } else {
                      ele.discount = 0
                    }
                  } else {
                    ele.discount = 0
                  }
                }
              })
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.loadStatus.error = true
        })
        .finally(() => {
          this.loadStatus.loading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
* {
  -webkit-overflow-scrolling: touch;
}
.main {
  background: #f6f6f6;
}
.searchmain {
  background: #ffffff;
  padding: 10px 14px;
}
.searchdiv {
  width: 350px;
  height: 34px;
  .van-search {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 351px;
    padding: 0;
    background-color: #fff;
  }
}

.fangdajing {
  float: left;
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-left: 5px;
}

.searchinput {
  float: left;
  font-size: 13px;
  color: #999999;
  height: 34px;
  line-height: 34px;
  width: 300px;
  background: #f0f2f5;
}

.vantabdiv {
  background: #ffffff;
  padding-top: 12px;
}

.pxdiv {
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 1px;
  box-sizing: border-box;
}
.banner_img {
  padding: 12px;
  box-sizing: border-box;
  background: #fff;
  margin: -12px 0;
  img {
    border-radius: 4px;
  }
}
.pxul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 10px;
  margin-bottom: 1px;
}

.pxul li {
  position: relative;
  width: 100%;
  font-size: 16px;
  color: #141414;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pxul li.pxworldselect {
  color: #ff001c;
  transition: all 1s;
}
.pxul li.pxworldselect::after {
  position: absolute;
  content: "";
  height: 2px;
  background: #ff001c;
  width: 50%;
  left: 50%;
  bottom: -10px;
  transform: translate(-50%, -50%);
  transition: all 1s ease;
}

.pxarrow {
  width: 12px;
  height: 16px;
  margin-left: 2px;
}

/deep/ .van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 40px;
}

/deep/ .van-tabs__nav--line {
  height: 30px;
  padding: 0 7px;
}

/deep/ .van-tab {
  width: 80px;
  height: 26px;
  border-radius: 15px;
  font-size: 15px;
  color: #141414;
  display: inline-block;
  line-height: 26px;
}

/deep/ .van-tab--active {
  color: #ff001c;
}

/deep/ .van-tabs__line {
  display: none;
}

/deep/ .van-tab {
  width: auto;
}

.goodsList {
  margin-top: 10px;
  width: 375px;
  z-index: 2;
  position: relative;
  h2 {
    margin-top: 32px;
    font-size: 16px;
  }
}

// 筛选
.grey {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}

.shuaixuandiv {
  width: 280px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  background: #ffffff;
  height: 100%;
}

.sxmain {
  height: 100%;
  overflow: scroll;
  padding-bottom: 90px;
}

.bottombtn {
  position: absolute;
  bottom: 0;
  height: 70px;
  background: #fff;
}

.sxreset {
  width: 90px;
  height: 60px;
  border-bottom: 1px solid black;
  line-height: 60px;
  text-align: center;
  color: black;
  font-size: 16px;
  margin-left: 30px;
  float: left;
}

.sxenter {
  height: 60px;
  width: 90px;
  color: #fff;
  background: #ed2e2e;
  border-radius: 4px;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  margin-left: 30px;
  float: left;
}

.zzbox {
  padding-top: 25px;
  width: 300px;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  box-sizing: border-box;
  .all_box {
    height: calc(100% - 40px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    h1 {
      text-align: left;
      font-size: 14px;
      padding: 12px 0 0 12px;
      box-sizing: border-box;
    }
  }
  .btnbox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #ffffff;
    button {
      width: 45%;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 15px;
    }
    .btn2 {
      color: #ffffff;
      background: red;
    }
  }
  h1 {
    margin: 5px 0;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 5px;
    .lis {
      margin-top: 5px;
      margin-left: 3%;
      height: 25px;
      line-height: 25px;
      background: #f8f8f8;
      border-radius: 15px;
      text-align: center;
      width: 28%;
      padding: 0 10px;
      font-size: 12px;
    }
    .li {
      width: 30%;
      //flex: 1;
      margin-top: 5px;
      height: 25px;
      line-height: 25px;
      background: #f8f8f8;
      border-radius: 15px;
      text-align: center;
      font-size: 12px;
      position: relative;
      span {
        display: inline-block;
        //width: 100%;
        width: fit-content;
        //white-space: nowrap;
        text-align: center;
        line-height: 1;
        transform: scale(0.6);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.6);
      }
    }
    .bgipt {
      color: rgb(236, 185, 185);
      background: rgb(250, 238, 238);
      border: 1px solid rgb(236, 185, 185);
    }
  }
}
.close {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .ipt {
    width: 100px;
    border-radius: 10px;
    height: 25px;
    background: #f8f8f8;
    text-align: center;
  }
  div {
    width: 20px;
    height: 1px;
    background: #eeeeee;
  }
}
</style>
